import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout/MainLayout";
import { graphql } from "gatsby";
import BookReference from "../components/BookReference/BookReference";
import { isEmptyBookReference } from "../../helpers";
import "./bookReferencesTemplate.css";

export default class BookReferencesTemplate extends React.Component {
  render() {
    const { allBooks } = this.props.data;
    const books = allBooks.edges.map(item => item.node);
    const view = books.map((book, index) => {
      const { title, references } = book;
      if (!isEmptyBookReference({ references })) {
        return (
          <li className="BookReferences-listItem">
            <BookReference title={title} references={references} />
          </li>
        );
      }
      return null;
    });

    return (
      <Layout>
        <div className="BookReferences-container">
          <Helmet title="Book References from all books" />
          <h3>Compilation of Book References</h3>
          <ul className="BookReferences-list">{view}</ul>
        </div>
      </Layout>
    );
  }
}

export const referencesQuery = graphql`
  query ReferencesQuery {
    allBooks(sort: { fields: dateRead, order: DESC }) {
      edges {
        node {
          title
          authors
          references {
            refAuthor
            refBook
            refNotes
            refLink
          }
        }
      }
    }
  }
`;
